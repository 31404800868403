import React, { createContext,useContext } from "react"

export const PageContext = createContext()

export const PageContextProvider = ({context: pageContext,children}) => {

  return (
    <PageContext.Provider value={pageContext}>
      {children}
    </PageContext.Provider>
  )
}

export const usePageContext = () => useContext(PageContext);
