import React, { createContext,useContext } from "react"

export const PathContext = createContext()

export const PathProvider = ({pathname,children}) => {

  return (
    <PathContext.Provider value={pathname}>
      {children}
    </PathContext.Provider>
  )
}

export const usePath = () => useContext(PathContext);
