import { getCurrentLangKey } from 'ptz-i18n';
import { usePath } from './PathContext';

export const langs = [ 'fr', 'en', 'es', 'de' ];
const defaultLangKey = 'de';

export const useLangKey = () => {
    const url = usePath();
    const langKey = getCurrentLangKey(langs, defaultLangKey, url);
    return langKey
};
