/** @jsx jsx */
import { jsx } from 'theme-ui';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { useMemo } from 'react';

import GlobalCSS from './global-css';
import SiteContainer from './site-container';
// import AlertBanner from 'gatsby-theme-catalyst-core/src/components/alert-banner';
import Header from '../../gatsby-theme-catalyst-header-top/components/header';
import Main from './main';
import ContentContainer from './content-container';
//import Footer from 'gatsby-theme-catalyst-core/src/components/footer';
import { PathProvider } from '../../utils/PathContext';
import { PageContextProvider } from '../../utils/PageContext';

import { useScrollYPosition } from 'react-use-scroll-position';
import { NavProvider } from './nav-context';
import { ThemeProvider } from 'theme-ui';
//import theme from '../../gatsby-plugin-theme-ui/index';

import '@fontsource/oswald';
import '@fontsource/poppins';

const ScrollTop = () => {
	const scrollY = useScrollYPosition();
	const visible = scrollY >= 400;
	return useMemo(
		() => (
			<div
				onClick={() => {
					return window.scroll({ top: 0, behavior: 'smooth' });
				}}
				sx={{
					position: 'fixed',
					bottom: 60,
					right: 60,
					border: 0,
					zIndex: 9999,
					cursor: 'pointer',
					opacity: visible ? 1 : 0,
					transition: '.5s all ease-out',
					//pointerEvents: 'none',
					variant: 'text.header',
					fontSize: [ 6, null, 7 ],
					transform: 'rotate(180deg)'
				}}
			>
				V
			</div>
		),
		[ visible ]
	);
};

const SiteLayout = ({ children, pathname, pageContext }) => {
	return (
		<PageContextProvider context={pageContext}>
			<PathProvider pathname={pathname}>
				{/* <ThemeProvider theme={theme}> */}
					<NavProvider>
						<SiteContainer>
							<GlobalCSS />
							<SkipNavLink />
							{/* <AlertBanner /> */}
							<Header />
							<Main>
								<SkipNavContent />
								{/* <ContentContainer> */}
								{children}
								{/* </ContentContainer> */}
								<ScrollTop />
							</Main>
							{/* <Footer /> */}
						</SiteContainer>
					</NavProvider>
				{/* </ThemeProvider> */}
			</PathProvider>
		</PageContextProvider>
	);
};

export default SiteLayout;
