/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { useLangKey, langs } from '../utils/lang';
import { usePageContext } from '../utils/PageContext';
import { Link } from 'gatsby';
import { useVariants } from './useVariants';

const lang_order = [ 'fr', 'de', 'es', 'en' ];

export const LanguageChooser = () => {
	const lang_urls = usePageContext();
	const langKey = useLangKey();
	//const styles = useVariants([ 'text.header', 'text.blink' ]);

	return (
		<div
			sx={{
				display: 'inline-block',
				gridColumn: 3,
				gridRow: 1
			}}
		>
			{lang_order.map((key, index) => {
				const style = key === langKey ? {  variant: 'text.selected' } : { variant: 'text.header' };
				return (
					<React.Fragment>
						{index !== 0 && <span sx={{ variant: 'text.header' }}>/</span>}
						<Link
							sx={{
								//...{key === langKey ? {vartiant: 'slected'} : {}},
								...style,
								mx: 1
							}}
							to={lang_urls[key]}
						>
							{key}
						</Link>
					</React.Fragment>
				);
			})}
		</div>
	);
};
